import { createStore } from 'vuex';
import { menuModule } from './modules/menu';
import { userModule } from './modules/user';
import { configModule } from './modules/config';
import { materialGroupModule } from './modules/materialGroup';
import { dictModule } from './modules/dict';
import { supplierModule } from './modules/supplier';
import { customerModule } from './modules/customer';
import { i18nModule } from './modules/i18n';

import createPersistedState from 'vuex-persistedstate'; //导入库

export default createStore({
	modules: {
		menu: menuModule,
		user: userModule,
		config: configModule,
		materialGroup: materialGroupModule,
		dict: dictModule,
		supplier: supplierModule,
		customer: customerModule,
		i18n: i18nModule,
	},
	plugins: [
		createPersistedState({
			key: 'plugins',
			paths: ['user', 'config', 'materialGroup', 'dict', 'supplier', 'customer', 'i18n'], // 这里便只会缓存user下的state值
		}),
	],
});
