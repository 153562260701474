export default {
	material_offer_edit: {
		are_you_sure_to_batch_delete_the_materials: '是否确定批量删除你勾选的物料？',
		are_the_selected_materials_marked_as_matching_correctly: '是否把勾选的物料标记为匹配正确？',
		please_select_the_materials_that_need_to_be_deleted: '请勾选需要批量删除的物料!',
		please_check_if_the_materials_in_the_quotation_are_what_you_need:
			'请核对报价单中物料是否是你需要的。',
		view_operation_prompts: '查看操作提示',
		if_they_are_not_what_you_need_you_can_delete_them_directly_or_contact_the_sales_manager_for_assistance_in_making_corrections:
			'如果不是你需要的，可以直接删除或联系销售经理协助修正。',
		please_confirm_whether_the_quantity_and_price_in_the_quotation_are_correct:
			'请确认报价单中数量与价格是否正确。  ',
		you_can_directly_modify_the_purchase_quantity_of_each_item_based_on_your_actual_needs:
			'你可以根据自己实际所需，直接修改每一列物料的采购数量。',
		please_select_the_materials_that_require_batch_setting_of_interest_rates:
			'请勾选需要批量设定利率的物料!',
		please_select_the_materials_that_require_batch_setting_of_quantity:
			'请勾选需要批量设定数量的物料!',
		if_you_confirm_that_all_the_information_in_the_quotation_is_correct_click_the_save_button:
			'如果确认报价单各项信息无误，点击保存按钮。',
		after_clicking_the_save_button_the_sales_manager_will_see_the_updated_quotation:
			'点击保存按钮后，销售经理会看到修改保存后的报价单。',
		please_select_the_materials_that_require_batch_operation: '请勾选需要批量操作的物料!',
		if_you_have_any_questions_regarding_the_operation_feel_free_to_contact_the_sales_manager_you_are_working_with_at_any_time:
			'如果你操作上有任何疑问，可以随时联系与你对接的销售经理。',
		import_exception_please_try_again: '导入异常，请重新操作!',
		please_enter_the_quantity: '请输入数量',
		all_materials: '全部物料',
		kind_reminder: '温馨提示',
		matched_materials: '已匹配的物料',
		unmatched_materials: '未匹配的物料',
		materials_to_be_verified: '待核对的物料',
		lock_the_quotation: '锁定报价单',
		enter_keywords: '输入关键词',
		enter_material_code: '输入物料编码',
		batch_set_quantity: '批量设定数量',
		batch_labeling_is_correct: '批量标记正确',
		customer_material_code: '客户物料编码',
		customer_inquiry_name: '客户询价名称',
		brand: '品牌',
		model: '型号',
		material_name: '物料名称',
		material_description: '物料描述',
		quality: '质量',
		colour: '颜色',
		version: '版本',
		quantity: '数量',
		edit: '编辑',
		cancel: '取消',
		delete: '删除',
		batch_delete: '批量删除',
		share_with_customers: '分享客户',
		back: '返回',
		save: '保存',
		saved: '已保存',
		action: '操作',
		correct: '修正',
		confirm: '确认',
		download_quotation: '下载报价单',
		please_enter_search_keywords: '请输入搜索关键词',
		required_materials_by_customer: '客户所需物料',
		generate_pi_list: '生成PI列表',
		add_quotation_items: '新增报价物料',
		bulk_set_interest_rates: '批量设定利率',
		inquiry_name: '询价名称',
		cost: '成本',
		interest_rate: '利率',
		exchange_rate: '汇率',
		material_code: '物料编码',
		english_name: '英文名称',
		chinese_name: '中文名称',
		series: '系列',
		please_choose: '请选择',
		choose: '选择',
		query: '查询',
		reset: '重置',
		confirm_the_addition: '确认新增',
		please_enter: '请输入',
		interest_rate_cannot_be_lower_than_001: '利率不能低于0.01',
		interest_rate_cannot_exceed_1: '利率小于1',
		quantity_cannot_be_less_than_1: '数量不能低于1',
		quantity_cannot_exceed_99999: '数量不能高于99999',
		please_enter_a_valid_number: '请输入有效数字',
		newly_added: '新增',
		main_image: '主图',
		copy: '复制',
		remarks: '备注',
		cost_price: '成本价',
		sale_price: '销售价',
		whole: '全部',
		please_add_materials_first: '请先新增物料',
		customer_materials: '客户物料',
		correct_data: '修正数据',
		material_code_must_be_filled_in: '物料编码必须填写',
		classification: '分类',
		product_name: '品名',
		chinese_product_name: '中文产品名',
		english_product_name: '英文产品名',
		describe: '描述',
		to_b_interest_rate: 'To B 利率',
		to_b_sales_price: 'To B 销售价',
		create_time: '创建时间',
		quotation_time: '报价时间',
		choice: '选择',
		please_choose_a_category: '请选择分类',
		please_select_the_model: '请选择型号',
		add_quotation_data: '添加报价数据',
		title_name: '标题名称',
		code: '编码',
		image_loading_failed: '图片加载失败',
		batch_setting: '批量设定',
		success: '成功',
		failed: '失败',
		ok: '确认',
	},
};
